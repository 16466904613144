<template>
  <button class="app-text-button" @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'AppTextButton',
}
</script>

<style scoped lang="scss">
.app-text-button {
  font-family: LuciferSans;
  font-size: 14px;
  padding: 0.25rem;
  color: var(--color-primary);
  background: none;
  &[disabled] {
    color: var(--color-grey);
    background: none !important;
  }
}
</style>
