import { render, staticRenderFns } from "./AccountRecovery.vue?vue&type=template&id=8ab09ab6&scoped=true"
import script from "./AccountRecovery.vue?vue&type=script&lang=js"
export * from "./AccountRecovery.vue?vue&type=script&lang=js"
import style0 from "./AccountRecovery.vue?vue&type=style&index=0&id=8ab09ab6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ab09ab6",
  null
  
)

export default component.exports