var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Modal',{ref:"modal",staticStyle:{"padding":"1rem"},attrs:{"overlay":"","bg":_vm.background,"non-closable":_vm.nonClosable,"x-style":{
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    minHeight: '200px',
    width: '100%',
    borderRadius: '17px',
    padding: '1rem',
    boxShadow: '0 0 5px #0008',
    alignItems: 'center',
    transform: 'translateX(0)',
  }},on:{"close":function($event){return _vm.$emit('close')},"hide":function($event){return _vm.$emit('hide')},"show":function($event){return _vm.$emit('show')}}},[(_vm.hasCloseButton)?_c('AppNavButton',{staticStyle:{"margin-left":"auto"},attrs:{"icon":"close"},on:{"click":() => _vm.$emit('close') && _vm.$refs.modal.hide()}}):_vm._e(),_vm._t("default"),(_vm.title || _vm.text)?_c('div',{staticStyle:{"display":"grid","align-content":"center","justify-content":"center","width":"100%","text-align":"center","padding":"0 2.5rem"},style:({
      alignSelf: _vm.nonClosable ? 'flex-end' : 'flex-start',
      marginTop: _vm.hasCloseButton ? '0rem' : '2rem',
    })},[(_vm.title)?_c('p',{staticStyle:{"font-size":"14px","font-family":"LuciferSans","color":"#000","text-transform":"uppercase"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.text)?_c('p',{staticStyle:{"font-size":"14px","color":"#333","margin-top":"1.5rem","padding":"0 1rem"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e()]):_vm._e(),(_vm.cta || _vm.altCta)?_c('div',{staticStyle:{"margin-top":"auto","display":"grid","":"center","gap":"1rem","padding-top":"2rem"}},[(_vm.cta)?_c('XButton',{attrs:{"type":"large"},on:{"click":function($event){return _vm.$emit('cta')}}},[_vm._v(_vm._s(_vm.cta))]):_vm._e(),_c('p',{staticStyle:{"color":"var(--color-primary)","text-decoration":"underline","text-align":"center","cursor":"pointer","font-family":"DDin"},on:{"click":function($event){return _vm.$emit('alt-cta')}}},[_vm._v(" "+_vm._s(_vm.altCta)+" ")])],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }