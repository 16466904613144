<template>
  <div class="app-back-header">
    <router-link
      v-if="to"
      :to="to"
      style="padding: 0.25rem; display: flex; align-items: center"
    >
      <IconArrowLeft style="color: black" />
    </router-link>
    <button
      v-else
      style="padding: 0.25rem; display: flex; align-items: center"
      @click="$emit('back')"
    >
      <IconArrowLeft style="color: black" />
    </button>
    <p class="app-back-header-title">
      <slot></slot>
    </p>
    <AppTextButton v-if="cta" :disabled="ctaDisabled" @click="$emit('cta')">{{
      cta
    }}</AppTextButton>
  </div>
</template>

<script>
import IconArrowLeft from './icons/arrows/IconArrowLeft.vue'
import AppTextButton from './AppTextButton.vue'

export default {
  name: 'AppBackHeader',
  components: { IconArrowLeft, AppTextButton },
  props: { cta: String, to: String, ctaDisabled: Boolean },
}
</script>

<style scoped lang="scss">
.app-back-header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 2rem;
  & > *:nth-child(1) {
    justify-self: flex-start;
  }
  & > *:nth-child(3) {
    justify-self: flex-end;
  }
  align-items: center;
}
.app-back-header-title {
  font-size: 14px;
  font-family: LuciferSans;
  text-align: center;
  text-transform: uppercase;
}
</style>
