<template>
  <div
    style="
      display: grid;
      grid-template-rows: auto auto 1fr;
      gap: 3rem;
      height: 100%;
    "
    class="container-xs"
  >
    <AppBackHeader to="/login">{{
      $t('account.forgot-password')
    }}</AppBackHeader>
    <form style="display: grid; gap: 0.5rem" class="x-form" @submit.prevent>
      <x-label tag="label" for="email">{{ $t('account.email') }}</x-label>
      <form-input
        id="email"
        v-model="email"
        v-focus
        type="email"
        required
        style="text-transform: lowercase"
        autocapitalize="off"
        @valid="value => (formValid = !!value)"
      />
    </form>
    <x-button
      id="submit"
      type="large"
      style="margin-top: auto"
      :disabled="!formValid"
      :is-loading="isLoading"
      @click="recover"
    >
      {{ $t('account.reset-password') }}
    </x-button>
    <InfoModal
      ref="errorModal"
      title="UH OH! LOOKS LIKE SOMETHING WENT WRONG."
      hide-close-button
      cta="Try Again"
      @cta="$refs.errorModal.hide()"
    />
  </div>
</template>

<script>
import InfoModal from '../../components-rf/modals/InfoModal.vue'
import AppBackHeader from '../../components-rf/AppBackHeader.vue'
export default {
  name: 'AccountRecovery',
  components: { InfoModal, AppBackHeader },
  data() {
    return {
      formValid: false,
      isLoading: false,
    }
  },
  methods: {
    recover() {
      if (!this.formValid) return
      this.isLoading = true
      this.$store
        .dispatch('RecoveryModule/recoverPassword', { email: this.email })
        .then(() => this.$router.push('/account-recovery/token'))
        .catch(() => {
          this.$refs.errorModal.show()
        })
    },
    skipToToken() {
      if (!this.formValid) return
      this.$router.push('/account-recovery/token')
    },
  },
  computed: {
    email: {
      get() {
        return this.$store.state.RecoveryModule.email
      },
      set(value) {
        this.$store.commit('RecoveryModule/setEmail', value)
      },
    },
  },
}
</script>

<style scoped lang="scss">
.x-form {
  @include m() {
    margin-top: 2rem;
  }
}
</style>
