<template>
  <Modal
    ref="modal"
    overlay
    style="padding: 1rem"
    :bg="background"
    :non-closable="nonClosable"
    :x-style="{
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '400px',
      minHeight: '200px',
      width: '100%',
      borderRadius: '17px',
      padding: '1rem',
      boxShadow: '0 0 5px #0008',
      alignItems: 'center',
      transform: 'translateX(0)',
    }"
    @close="$emit('close')"
    @hide="$emit('hide')"
    @show="$emit('show')"
  >
    <AppNavButton
      v-if="hasCloseButton"
      icon="close"
      style="margin-left: auto"
      @click="() => $emit('close') && $refs.modal.hide()"
    />
    <slot></slot>
    <div
      v-if="title || text"
      style="
        display: grid;
        align-content: center;
        justify-content: center;
        width: 100%;
        text-align: center;
        padding: 0 2.5rem;
      "
      :style="{
        alignSelf: nonClosable ? 'flex-end' : 'flex-start',
        marginTop: hasCloseButton ? '0rem' : '2rem',
      }"
    >
      <p
        v-if="title"
        style="
          font-size: 14px;
          font-family: LuciferSans;
          color: #000;
          text-transform: uppercase;
        "
      >
        {{ title }}
      </p>
      <p
        v-if="text"
        style="
          font-size: 14px;
          color: #333;
          margin-top: 1.5rem;
          padding: 0 1rem;
        "
      >
        {{ text }}
      </p>
    </div>

    <div
      v-if="cta || altCta"
      style="margin-top: auto; display: grid; justify-content;: center; gap: 1rem; padding-top: 2rem;"
    >
      <XButton v-if="cta" type="large" @click="$emit('cta')">{{ cta }}</XButton>
      <p
        style="
          color: var(--color-primary);
          text-decoration: underline;
          text-align: center;
          cursor: pointer;
          font-family: DDin;
        "
        @click="$emit('alt-cta')"
      >
        {{ altCta }}
      </p>
    </div>
  </Modal>
</template>

<script>
import Modal from '../../components/utils/Modal.vue'
import AppNavButton from '../AppNavButton.vue'
import XButton from '../../components/common/global/XButton.vue'

export default {
  name: 'InfoModal',
  components: { Modal, AppNavButton, XButton },
  props: {
    title: String,
    text: String,
    cta: String,
    altCta: String,
    background: String,
    nonClosable: Boolean,
    hideCloseButton: Boolean,
  },
  computed: {
    hasCloseButton() {
      return !this.nonClosable && !this.hideCloseButton
    },
  },
  methods: {
    show() {
      this.$refs.modal.show()
    },
    hide() {
      this.$refs.modal.hide()
    },
  },
}
</script>

<style scoped lang="scss"></style>
